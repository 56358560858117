import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Table, Button, Modal, Input} from 'antd';
import {Context} from '../../AppContext';
import Supplier from '../../Models/Supplier';
import {Link} from 'gatsby';
import SupplierForm from '../../Forms/SupplierForm';
import useSupplierList from '../../hooks/use-supplier-list';

const labels = Supplier.labels;

export default function SupplierPage(props) {
  const app = useContext(Context);
  const [search, setSearch] = useState('');
  const {
    records,
    total,
    filters,
    onUpdate,
    setFilters,
    loading,
  } = useSupplierList({});

  const columns = [
    {
      title: labels.name,
      render: (record) => (
        <Link to={`/supplier/?id=${record.id}`}>{record.name}</Link>
      ),
    },
    {
      title: labels.company_title,
      dataIndex: 'company_title',
      key: 'company_title',
    },
    {title: labels.uni_number, dataIndex: 'uni_number', key: 'uni_number'},
    {title: labels.tel, dataIndex: 'tel', key: 'tel'},
    {title: labels.fax, dataIndex: 'fax', key: 'fax'},
    {title: labels.address, dataIndex: 'address', key: 'address'},
    {title: labels.pay_period, dataIndex: 'pay_period', key: 'pay_period'},
  ];

  return (
    <Wrapper>
      <div className="action-bar">
        <Input
          value={search}
          placeholder="搜尋姓名"
          style={{width: 250}}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => onUpdate(search)}>
          刷新
        </Button>
        <div style={{flex: 1}} />
        {/* <Button type="primary">匯出廠商清單</Button> */}
        <Button
          type="primary"
          onClick={() => {
            app.actions.setModalWidth(900);
            app.actions.setModal(
              <SupplierForm
                record={new Supplier()}
                onUpdate={() => onUpdate(search)}
              />,
            );
          }}>
          ＋
        </Button>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={records}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onChange={(pagination) =>
          setFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
