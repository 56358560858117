import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Context} from '../AppContext';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function useSupplierList({noPage = false}) {
  const app = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [filters, _setFilters] = useState({
    ...PAGINATION_INIT,
  });
  const [total, setTotal] = useState(0);

  const getRecords = useCallback(
    async (search = '') => {
      setLoading(true);
      try {
        const params = {
          query: {
            name: {$regex: search},
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };

        if (noPage) {
          delete params.paging;
          delete params.sorting;
        }
        let resp = await app.actions.getSuppliers(params);
        if (noPage) {
          setRecords(resp);
          setTotal(resp.length);
        } else {
          setRecords(resp.results);
          setTotal(resp.total);
        }
      } catch (ex) {
        console.warn(ex);
      }
      setLoading(false);
    },
    [app.actions, filters],
  );

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return {
    records,
    total,
    onUpdate: getRecords,
    filters,
    setFilters,
    loading,
  };
}
